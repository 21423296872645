import React from 'react';
import MediaCenter from '../../components/MediaCenter/index';
import { graphql } from 'gatsby';
import { modifyContent } from '../../utils/helpers';

export const query = graphql`
        query{
            content: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#media-center" }}}
                }
            ){
                nodes {
                    plaintext
                    meta_title
                    meta_description
                }
            }
            pressRelease: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#press-release" }}}
                }
            ){
                nodes {
                    plaintext
                }
            }
            news: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: { eq: "#news"}}}
                }
            ) {
                nodes {
                    plaintext
                }
            }

            opinionCarousel: allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: "opinion"}}}},
            limit: 3,
            ) {
                edges {
                    node {
                    ...GhostPostFields
                    }
                }
            }
        }
    `;

const MediaCenterPage = props => {
    const content = modifyContent(props.data)
    const title = props.data.content?.nodes[0].meta_title;
    const description = props.data.content?.nodes[0].meta_description;
    const opinions = props.data.opinionCarousel.edges;

    return (
        <MediaCenter
            data={content.content}
            news={content.news}
            opinionCarousel={opinions}
            pressRelease = {content.pressRelease}
            title={title}
            description={description}
            location={props.location}
        />
    );
}

export default MediaCenterPage;
