import styled from 'styled-components';
import { deviceLessThan } from '../../styles/breakpoints';

export const MediaContainer = styled.div`
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width:75%;
    max-width: 1200px;
    padding:70px 0px 50px;
    justify-content: center;
    &::before{
        content: "";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 301px 136px;
        position: absolute;
        top: 0px;
        right: 20%;
        height: 136px;
        width: 301px;
        z-index: -1;
    };
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 77px 300px;
        position: absolute;
        bottom: -150px;
        right: 0px;
        height: 300px;
        width: 77px;
        z-index: -1;
    };
    @media ${deviceLessThan.laptop}{
        width:95%;
    }
    @media ${deviceLessThan.tablet}{
        display: unset;
        &::before{
            content: "";
            background:unset;
        };
        &::after{
            content: "";
            background:unset;
        };
    }
`;

export const MediaCenterContainer = styled.div`
    width:75%;
    max-width: 1200px;
    margin:100px auto 0px;
    &::before{
        content: "";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 543px 726px;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 726px;
        width: 543px;
        z-index: -1;
    };
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 230px 280px;
        position: absolute;
        bottom: -460px;
        left: -35px;
        height: 280px;
        width: 230px;
        z-index: -1;
    };
    @media ${deviceLessThan.laptop}{
        margin:50px auto 0px;
        width:95%;
        &::after{
            bottom: -200px;
        };
        &::before{
            background-size: 400px 545px;
        }
    }
    @media ${deviceLessThan.tablet}{
        margin:38px auto 0px;
        text-align:center;
        max-width:480px;
        &::before{
            content: "";
            width: 219px;
            border-radius: 50%;
            height: 219px;
            background-color: #eef8fd;
            position: absolute;
            top: 0px;
            left: -70px;
        }
        &::after{
            content: "";
            background:unset;
        };
    }
`;

export const MediaCardWrapper = styled.div`
    box-sizing:border-box;
    width: 50%;
    height: auto;
    position:relative;
    &:nth-of-type(2){
        &::after{
            content:"";
            background:url(${props => props.background}) no-repeat;
            background-size:108px 108px;
            width:108px;
            height:108px;
            position:absolute;
            top: -70px;
            right: -70px;
            z-index: -1;
        }
    }
    &:nth-of-type(2n+1){
        padding:0px 23px 46px 0px;
    };
    &:nth-of-type(2n){
        padding: 0px 0px 46px 23px;
    };
    @media ${deviceLessThan.tablet}{
        &:nth-of-type(2n+1){
            padding:${props => props.sPadding};
        };
        &:nth-of-type(2n){
            padding:${props => props.sPadding};
        };
        text-align:center;
        margin:0 auto;
        width: 90%;
        &::after{
            content:"";
            background:unset;
            background-size:108px 108px;
            width:108px;
            height:108px;
            position:absolute;
            top: -70px;
            right: -70px;
            z-index: -1;
        };
    }
`;

export const MediaCardContainer = styled.div`
    border-radius: 6px;
    box-shadow: 0 0 13px 1px rgba(0,0,0,0.1);
    padding: 16px;
    text-align:${props => props.align};
    background: white;
    height: 100%;
    padding:25px 20px;
    justify-content:${props => props.jContent};
    display:${props => props.display};
    flex-direction:${props => props.flexDirection};
    @media ${deviceLessThan.tablet}{
        text-align:unset;
        box-shadow:0 0 16px 0 rgba(0, 0, 0, 0.1);
        padding: 16px;
    }
`;

export const MediaKitWrapper = styled.div`
    border-bottom:2px solid #d8d8d854;
    display:flex;
    cursor:pointer;
    padding:20px 0px;
    &:last-child{
        border-bottom:unset;
        padding:20px 0px 0px;
    }
    &:first-child{
        padding:0px 0px 20px;
    }
`;

export const MediKitContainer = styled.div`
    border-radius:4px;
    border:1.5px solid #e5e7eb;
    padding:20px;
    margin:0px 0px 20px 0px;
`;

export const MediaKitDocument = styled.div`
    flex:1;
    text-align:left;
    padding:0px 0px 0px 13px;
    @media ${deviceLessThan.tablet}{
        & p:last-child{
            font-size:13px;
            line-height:unset;
        }
    }
`;

export const PressReleaseWrapper = styled.div`
    border-radius: 4px;
    border: 1.5px solid #e5e7eb;
    text-align:left;
    flex:1;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    cursor:pointer;
    padding:20px 15px;
    margin:0px 0px 20px 0px;
    @media ${deviceLessThan.tablet}{
        margin:0px 0px 10px 0px;
        padding:10px;
    }
`;
