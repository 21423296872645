import React from 'react';
import Heading from '../common/ui/Heading';
import Text from '../common/ui/Text';
import { MediaCardWrapper, MediaCardContainer, MediKitContainer } from './Component';
import PressRelease from './PressRelease';
import News from './News/index';
import Opinion from './Opinion/index';
import MediKit from './MediaKit';
import CutomButton from '../common/ui/CutomButton';
import CustomLink from '../common/ui/CustomLink';

const MediaCard = props => (
     <MediaCardWrapper
        padding="45px"
        background={props.background}
        sPadding="0px 0px 20px"
     >
         <MediaCardContainer
            align="center"
            display="flex"
            flexDirection="column"
            jContent="space-between"
         >
            <Heading
                color="MEDIUM_BLACK"
                padding="0px 0px 30px"
                sPadding="0px 0px 15px"
                size="sm"
            >
                {props.heading}
            </Heading>
            {props.carousel && <News data={props.carousel} />}
            {props.opinionCarousel && <Opinion data={props.opinionCarousel} />}
            {props.pressPost && props.pressPost.map((el, i) => <PressRelease key={i} {...el} /> )}
            {props.content && <Text
                color="MEDIUM_BLACK"
                size="lg"
                padding="0px 15px 20px"
                lineHeight="1.67"
            >
                {props.content}
            </Text>}
            {props.mediaList && <MediKitContainer>{props.mediaList.map((el, i) => <MediKit key={i} {...el} />)}</MediKitContainer>}
            {props.seeAllButton && <CustomLink to={`${props.seeAllButton.link}`}>
                <CutomButton
                    borderradius="4px"
                    height="auto"
                    size="md"
                    width="max-content"
                    padding="10px 20px"
                    weight="large"
                >
                    {props.seeAllButton.text}
                </CutomButton>
            </CustomLink>}
         </MediaCardContainer>
     </MediaCardWrapper>
)

export default MediaCard;