import React from 'react'
import { Link } from 'gatsby';
import { ImageContainer, Image } from '../../common/ui/Image';
import Text from '../../common/ui/Text';
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import CustomLink from '../../common/ui/CustomLink';

SwiperCore.use([ Navigation, Pagination ]);

const News = ({ data }) => {
    return (
        <Swiper
            slidesPerView={1}
            spacebetweenslides={0}
            news="carousel"
            pagination={{ clickable:true }}
        >
            {data && data.map((el, i) => (
                <SwiperSlide
                    key={i}
                >
                    <CustomLink target="_blank" href={el.link} display="flex" flexDirection="column" height="100%">
                        {el.imgSrc && <ImageContainer
                            width="100%"
                            flex="1"
                            flexDirection="column"
                            backgroundColor="BACKGROUND_PLACEHOLDER"
                            display="flex"
                            jContent="center"
                        >
                            <Image src={el.imgSrc}/>
                        </ImageContainer>}
                        <ImageContainer
                            minheight="75px"
                            margin="0px"
                            minHeight="75px"
                            display="flex"
                            jContent="left"
                        >
                            <Image src={el.icon} width={el.width} style={{ alignSelf:"center" }}/>
                        </ImageContainer>
                        <Text
                            size="lg"
                            align="left"
                            weight="large"
                            lineHeight= "1.67"
                            padding="5px 0px 0px"
                        >
                            {el.title}
                        </Text>
                    </CustomLink>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default News;
