import React from 'react';
import Text from '../common/ui/Text';
import { MediaKitWrapper, MediaKitDocument } from './Component';
import { ImageContainer, Image } from '../common/ui/Image';
import { downloadImage, onButtonClick } from '../../utils/helpers';

const MediaKit = ({ title, content, imgSrc, link, pdf }) => {

    return (
        <MediaKitWrapper onClick={pdf === "true" ? onButtonClick.bind(null, link): downloadImage.bind(null, link, title)}>
            {imgSrc && <ImageContainer
                width="35px"
            >
                <Image 
                    src={imgSrc} 
                />
            </ImageContainer>}
            <MediaKitDocument>
                {title && <Text
                    size="lg"
                    weight="large"
                    lineHeight="1.09"
                    padding={content ? "":"8px 0px"}
                >
                    {title}
                </Text>}
                {content && <Text
                    size="sm"
                    color="LIGHTER_GREY"
                    lineHeight="1.43"
                    weight="medium"
                    opacity="unset"
                >
                    {content}
                </Text>}
            </MediaKitDocument>
        </MediaKitWrapper>
    )
}

export default MediaKit;