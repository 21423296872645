import React from 'react';
import { PressReleaseWrapper } from './Component';
import Tag from '../common/ui/Tag';
import Text from '../common/ui/Text';
import { onButtonClick } from '../../utils/helpers';

const PressRelease = ({ title, date, tag, link }) => {

    return (<PressReleaseWrapper onClick={onButtonClick.bind(null, link)}>
            <Tag
                size="xxxs"
                weight="large"
                color="TAG"
                lineHeight="2.2"
                letterSpacing="0.2px"
                width="max-content"
                padding="0px 8px 0px"
                backgroundColor="rgb(232 46 47 / 0.05)"
            >
                {tag}
            </Tag>
            <Text
                weight="large"
                size="lg"
                lineHeight="2"
                padding="15px 0px 13px"
            >
                {title}
            </Text>
            <Text
                size="xxs"
                color="MEDIUM_BLACK"
                weight="medium"
            >
                {date}
            </Text>
        </PressReleaseWrapper>)
}

export default PressRelease;