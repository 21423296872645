/**
 *
 * Tag
 *
 */

import React from 'react';
import styled from 'styled-components';
import { deviceLessThan, deviceGreaterThan } from '../../../styles/breakpoints';

const Tag = styled.p`
  display:${p => p.display};
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.size ? p.theme.web.text.size[p.size] : p.theme.web.text.size.md};
  font-weight: ${p => p.weight ? p.theme.web.text.weight[p.weight] : p.theme.web.text.weight.light};
  color: ${p => p.color ? p.theme.colors[p.color] : p.theme.colors.CHQBOOK_BLUE};
  text-align: ${p => p.align};
  opacity: ${props => props.opacity ? props.opacity : 1};
  margin-bottom: ${p => p.margin ? `${p.margin}px`: 0};
  margin-top: ${p => p.marginTop ? `${p.marginTop}px`: 0};
  margin:${p => p.margin && p.margin};
  position: ${p => p.position};
  right: ${p => p.right};
  padding:${p=>p.padding};
  top: ${p => p.top};
  width:${p => p.width && p.width };
  line-height:${p => p.lineHeight};
  background-color:${p => p.backgroundColor && p.backgroundColor};
  letter-spacing:${p => p.letterSpacing && p.letterSpacing};
  border: ${p => p.border};
  @media ${deviceLessThan.laptop} {
        margin:${p => p.mMargin && p.mMargin};
        font-size: ${p => p.size ? p.theme.mobile.text.size[p.size] : p.theme.mobile.text.size.md};
        font-weight: ${p => p.weight ? p.theme.mobile.text.weight[p.weight] : p.theme.mobile.text.weight.light};
    }
`

export default Tag;
