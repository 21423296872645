import React, { Fragment } from 'react';
import Layout from '../common/Layout';
import MetaData from '../common/meta/MetaData';
import MediaCard from './MediaCard';
import { MediaContainer, MediaCenterContainer } from './Component';
import Heading from '../common/ui/Heading';
import Text from '../common/ui/Text';
import { ImageContainer, Image } from '../common/ui/Image';

const MediaCenter = ({ data, title, description, keywords, location, news, pressRelease, opinionCarousel }) => {

    let newsContent = news && news.news && Array.isArray(news.news) && news.news.filter(n => n.isFeatured == true);

    return (
        <Fragment>
            <MetaData
                data={{}}
                title={title}
                description={description}
                keywords={keywords}
                location={location}
                type="website"
            />
            <Layout>
                <MediaCenterContainer
                    backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603737248/chqbookWeb/MediaCenter/oval-banner_3x.png"
                    backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575403/chqbookWeb/AboutUs/hexagon-no-fill_3x.png"
                >
                    <Heading
                        size="lg"
                        weight="medium"
                        lineHeight="1"
                        color="MEDIUM_BLACK"
                        padding="0px"
                    >
                        {data.heading}
                        <ImageContainer
                            margin="-7px 0px 0px"
                            sMargin="0px auto"
                            mWidth="190px"
                        >
                            <Image
                                src="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1602189754/chqbookWeb/0-5kb/red-underline.png"
                            />
                        </ImageContainer>
                    </Heading>
                    <Text
                        color="MEDIUM_BLACK"
                        size="xl"
                        opacity="0.7"
                        weight="normal"
                        lineHeight="1.7"
                        letterSpacing="-0.33px"
                        margin="35px 0px 0px"
                        width="565px"
                        sPadding="23px 0px 38px"

                    >
                        {data.description}
                    </Text>
                </MediaCenterContainer>
                <MediaContainer
                    backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575434/chqbookWeb/AboutUs/dot-pattern_3x.png"
                    backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png"
                >
                    {
                        data.sectionCard.map((el, i) => {
                            return (
                                <MediaCard
                                    key={i}
                                    heading={el.heading}
                                    carousel={el.carousel ? newsContent : ""}
                                    opinionCarousel={el.opinionCarousel ? opinionCarousel : ""}
                                    seeAllButton={el.seeAllButton}
                                    pressPost={el.pressPost ? pressRelease.pressList.slice(0, 2) : ""}
                                    content={el.content}
                                    mediaList={el.mediaList}
                                    background="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575511/chqbookWeb/AboutUs/hexagon-fill_3x.png"
                                />
                            )
                        })
                    }
                </MediaContainer>
            </Layout>
        </Fragment>
    )
}

export default MediaCenter;
