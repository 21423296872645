import React from 'react'
import { ImageContainer, Image } from '../../common/ui/Image';
import Text from '../../common/ui/Text';
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import CustomLink from '../../common/ui/CustomLink';

SwiperCore.use([ Navigation, Pagination ]);

const Opinion = ({ data }) => {
    return (
        <Swiper
            slidesPerView={1}
            spacebetweenslides={0}
            opinion="carousel"
            pagination={{ clickable:true }}
        >
            {data && data.map((el, i) => (
                <SwiperSlide
                    key={i}
                >
                    <CustomLink href={`/${el.node.slug}`} display="flex" flexDirection="column" height="100%">
                        {el.node.feature_image && <ImageContainer
                            width="100%"
                            flex="1"
                            flexDirection="column"
                            backgroundColor="BACKGROUND_PLACEHOLDER"
                            display="flex"
                            jContent="center"
                        >
                            <Image src={el.node.feature_image}/>
                        </ImageContainer>}
                        <Text
                            size="lg"
                            align="left"
                            weight="large"
                            lineHeight= "1.67"
                            padding="5px 0px 0px"
                        >
                            {el.node.title}
                        </Text>
                    </CustomLink>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default Opinion;
